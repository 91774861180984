import React from 'react';

interface Props {
  free_shipping: boolean;
  free_shipping_threshold: string | null;
  sub_total: string;
}

function FreeShippingAlert({ free_shipping, free_shipping_threshold, sub_total }: Props): React.ReactElement | null {
  if (free_shipping) {
    return (
      <div className="alert alert-shipping mt-3">
        <i className="material-icons mr-2">local_shipping</i> Congratulations! Your order receives{' '}
        <strong>FREE SHIPPING</strong>
      </div>
    );
  }
  if (free_shipping_threshold !== null) {
    const subTotal = parseFloat(sub_total);
    const shippingThreshold = parseFloat(free_shipping_threshold);
    const progress = subTotal / shippingThreshold;

    // If we have at least 70% we show progress bar and deficit in message
    if (progress >= 0.7) {
      const deficit = shippingThreshold - subTotal;
      return (
        <div className="alert alert-shipping-progress mt-3">
          <div className="text">
            <i className="material-icons mr-2">local_shipping</i> Spend <strong>€{deficit.toFixed(2)}</strong> more for{' '}
            <strong>FREE SHIPPING</strong>
          </div>
          <div
            className="bar"
            style={{
              width: `${progress * 100}%`,
            }}
          />
        </div>
      );
    }

    return (
      <div className="alert alert-shipping-outline mt-3">
        <i className="material-icons mr-2">local_shipping</i> Orders above <strong>€{free_shipping_threshold}</strong>{' '}
        qualify for <strong>FREE SHIPPING</strong>
      </div>
    );
  }

  return null;
}

export default FreeShippingAlert;
