import Big from 'big.js';
import { format, isValid } from 'date-fns';
import { parseISO } from 'date-fns';

const NUMERIC = /^-?(\d+(\.\d*)?|\.\d+)(e[+-]?\d+)?$/i;

export const parseNumber = (value, asString = false, zeroOnFail = false): any => {
  if (!NUMERIC.test((value += ''))) {
    if (zeroOnFail) {
      return asString ? '0.00' : Big(0);
    }
    return null;
  }
  const v = Big(value);
  return asString ? v.toFixed(2) : v;
};

export const createOptionLoader = (listAction, extraParams) => (search, params) =>
  listAction({
    search,
    page_size: 10,
    ...extraParams,
    ...params,
  }).then(({ payload: { results } }) => results);

export const makeCancelable = (promise) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error)),
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

export const formatISO = (isoString, fmt) => (isoString ? format(parseISO(isoString), fmt) : null);

export const formatDate = (isoString) => formatISO(isoString, 'MMM. d, yyyy');
export const formatDateTime = (isoString) => formatISO(isoString, 'MMM. d, yyyy HH:mm');

// FIXME
export function parseDate(str: string | null): Date | undefined {
  if (!str) {
    return undefined;
  }
  if (str.match(/\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])/)) {
    const parsed = parseISO(str);
    if (isValid(parsed)) {
      return parsed;
    }
  }
  return undefined;
}

export function getCookie(name: string): string | null {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = ((cookies[i] || '') + '').trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export const coerceNullProps = {
  parse: (value: string) => {
    if (value === '') return null;
    return value;
  },
  format: (value: string | null) => {
    if (!value) {
      return '';
    }
    return value;
  },
};
