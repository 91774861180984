import React, { Component, Fragment } from 'react';
import { formValues, FormSection as Section } from 'redux-form';
import { Radio, Input, Checkbox, VATField } from '../Form';

import Address from './Address';
import { required } from '../validators';
import { Columns } from 'Components/Form/Layout';

const CompanyFields = () => (
  <Fragment>
    <Input label="Company name" name="title" type="text" autoComplete="organization" validate={[required]} required />

    <Input label="Registration No" name="registration_number" type="text" validate={[required]} />
    <VATField label="VAT registration No" name="vat_number" />
  </Fragment>
);

const IndividualFields = () => (
  <Fragment>
    <Columns colClassName="col-sm-6">
      <Input
        label="First name"
        name="first_name"
        type="text"
        autoComplete="given-name"
        validate={[required]}
        required
      />
      <Input label="Last name" name="last_name" type="text" autoComplete="family-name" validate={[required]} required />
    </Columns>
    <VATField label="VAT registration No" name="vat_number" />
  </Fragment>
);

const INDIVIDUAL = 'individual';
const COMPANY = 'company';

class Billing extends Component {
  render() {
    const { type, countries } = this.props;
    return (
      <Fragment>
        <Section name="billing_details">
          <h4>Billing details</h4>
          <Radio
            label="Are you purchasing as individual or company?"
            name="type"
            options={[
              { label: 'as individual', value: INDIVIDUAL },
              { label: 'as company', value: COMPANY },
            ]}
            required
          />
          {type === COMPANY ? <CompanyFields /> : <IndividualFields />}
          <Address countries={countries} />
        </Section>
        <Checkbox label="Shipping address same as billing address" name="shipping_same_as_billing" />
      </Fragment>
    );
  }
}

export default formValues({
  type: 'billing_details.type',
})(Billing);
