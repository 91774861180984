import { Prefix } from './types';

// https://ec.europa.eu/taxation_customs/vies/faq.html#item_11

export const prefixOptions: Prefix[] = [
  {
    code: 'AT',
    name: 'Austria',
    pattern: /^ATU\d{8}$/,
  },
  {
    code: 'BE',
    name: 'Belgium',
    pattern: /^BE[0,1]\d{9}$/,
  },
  {
    code: 'BG',
    name: 'Bulgaria',
    pattern: /^BG\d{9,10}$/,
  },
  {
    code: 'CY',
    name: 'Cyprus',
    pattern: /^CY\d{8}[A-Z]$/,
  },
  {
    code: 'CZ',
    name: 'Czech Republic',
    pattern: /^CZ\d{8,10}$/,
  },
  {
    code: 'DE',
    name: 'Germany',
    pattern: /^DE\d{9}$/,
  },
  {
    name: 'Denmark',
    code: 'DK',
    pattern: /^DK\d{8}$/,
  },
  {
    name: 'Estonia',
    code: 'EE',
    pattern: /^EE\d{9}$/,
  },
  {
    name: 'Greece',
    code: 'EL',
    pattern: /^EL\d{9}$/,
  },
  {
    name: 'Spain',
    code: 'ES',
    //  The first and last characters may be alpha or numeric; but they may not both be numeric.
    pattern: /^ES[A-Z0-9]\d{7}[A-Z0-9]$/,
  },
  {
    name: 'Finland',
    code: 'FI',
    pattern: /^FI\d{8}$/,
  },
  {
    name: 'France',
    code: 'FR',
    pattern: /^FR[A-Z0-9]{2}\d{9}$/,
  },
  {
    name: 'Croatia',
    code: 'HR',
    pattern: /^HR\d{11}$/,
  },
  {
    name: 'Hungary',
    code: 'HU',
    pattern: /^HU\d{8}$/,
  },
  {
    name: 'Ireland',
    code: 'IE',
    pattern: /^IE\d[A-Z0-9\+\*]\d{5}[A-Z]{1,2}$/,
  },
  {
    name: 'Italy',
    code: 'IT',
    pattern: /^IT\d{11}$/,
  },
  {
    name: 'Lithuania',
    code: 'LT',
    pattern: /^LT(\d{9}|\d{12})$/,
  },
  {
    name: 'Luxembourg',
    code: 'LU',
    pattern: /^LU\d{8}$/,
  },
  {
    name: 'Latvia',
    code: 'LV',
    pattern: /^LV\d{11}$/,
  },
  {
    name: 'Malta',
    code: 'MT',
    pattern: /^MT\d{8}$/,
  },
  {
    name: 'Netherlands',
    code: 'NL',
    pattern: /^NL[A-Z0-9\+\*]{12}$/,
  },
  {
    code: 'PL',
    name: 'Poland',
    pattern: /^PL\d{10}$/,
  },
  {
    code: 'PT',
    name: 'Portugal',
    pattern: /^PT\d{9}$/,
  },
  {
    code: 'RO',
    name: 'Romania',
    pattern: /^RO\d{2,10}$/,
  },
  {
    code: 'SE',
    name: 'Sweden',
    pattern: /^SE\d{12}$/,
  },
  {
    code: 'SI',
    name: 'Slovenia',
    pattern: /^SI\d{8}$/,
  },
  {
    code: 'SK',
    name: 'Slovakia',
    pattern: /^SK\d{10}$/,
  },
  {
    code: 'XI',
    name: 'Northern Ireland',
    pattern: /^XI(?:\d{9}(?:\d{3})?|GD\d{3}|HA\d{3})$/,
  },
];
