import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'clsx';
import { State, Step } from './types';
import { Action } from './actions';
import { Dispatch } from 'redux';
// import { actions } from './state';

const TITLES: Record<Step, string> = {
  CART: 'Shopping cart',
  ORDER: 'Order',
  SHIPPING: 'Shipping',
  PAYMENT: 'Payment',
  DONE: 'Sent',
};

function selectState({
  checkout,
  cart,
  urls: { checkoutUrl },
}: State): {
  step: Step;
  steps: readonly Step[];
  restored: boolean;
  checkoutUrl: string;
} {
  const { step, steps, restored } = checkout;

  const deliverable = checkout.restored ? checkout.order.deliverable : cart.estimate.deliverable;
  return {
    step,
    steps: deliverable ? steps : steps.filter((s) => s !== 'SHIPPING'),
    restored,
    checkoutUrl,
  };
}

export function Nav(): React.ReactElement {
  const { steps, step, restored, checkoutUrl } = useSelector(selectState);
  const dispatch: Dispatch<Action> = useDispatch();

  const currentIndex = steps.indexOf(step);

  return (
    <ul className="nav step-nav">
      {steps.map((s, idx) => {
        // can only go to first when restored
        const disabled = idx > currentIndex || (restored && idx !== 0);

        return (
          <li className="nav-item" key={s}>
            <button
              className={cx('nav-link btn btn-link', { disabled, current: currentIndex === idx })}
              onClick={
                disabled || s == 'DONE'
                  ? undefined
                  : () => {
                      restored
                        ? document.location.replace(checkoutUrl)
                        : dispatch({
                            type: 'checkout/navigate',
                            step: s,
                          });
                    }
              }
            >
              <span className="number">{idx + 1}</span> <span className="title">{TITLES[s]}</span>
            </button>
          </li>
        );
      })}
    </ul>
  );
}
