import React, { Fragment } from 'react';
import Summary from './Summary';

const Row = ({ title, price, quantity, unit_discount, unit_discount_sum, unit_discounted_sum, showCatDiscount }) => (
  <tr>
    <td>{title}</td>
    <td className="text-right">{price}</td>
    <td className="text-right">{quantity}</td>
    {showCatDiscount && (
      <Fragment>
        <td className="text-right">{unit_discount}</td>
        <td className="text-right">{unit_discount_sum}</td>
      </Fragment>
    )}

    <td className="text-right">{unit_discounted_sum}</td>
  </tr>
);

const Table = ({ items, summary, finalPrice, showCatDiscount }) => (
  <table className="table summary-table mt-1">
    <thead>
      <tr>
        <th>Item</th>
        <th className="text-right">Price, &euro;</th>
        <th className="text-right">Quantity</th>
        {showCatDiscount && (
          <Fragment>
            <th className="text-right">Discount, %</th>
            <th className="text-right">Discount, &euro;</th>
          </Fragment>
        )}
        <th className="text-right">Sum, &euro;</th>
      </tr>
    </thead>
    <tbody>
      {items.map((item, idx) => (
        <Row {...item} key={idx} showCatDiscount={showCatDiscount} />
      ))}
    </tbody>
    <Summary {...summary} finalPrice={finalPrice} colspan={showCatDiscount ? 5 : 3} />
  </table>
);

export default Table;
