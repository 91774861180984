import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Select, SimpleSelect, Input } from '../Form';
import { formValues } from 'redux-form';
import { required } from '../validators';
import { Columns } from '../Form/Layout';

export const countryLabel = (v) => (v ? v.name : v);
export const countryValue = (v) => (v ? v.code : v);

export const STATE_OR_PROVINCE = {
  US: [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'DC', label: 'District of Columbia' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
  ],
  CA: [
    { value: 'AB', label: 'Alberta' },
    { value: 'BC', label: 'British Columbia' },
    { value: 'MB', label: 'Manitoba' },
    { value: 'NB', label: 'New Brunswick' },
    { value: 'NL', label: 'Newfoundland and Labrador' },
    { value: 'NS', label: 'Nova Scotia' },
    { value: 'NT', label: 'Northwest Territories' },
    { value: 'NU', label: 'Nunavut' },
    { value: 'ON', label: 'Ontario' },
    { value: 'PE', label: 'Prince Edward Island' },
    { value: 'QC', label: 'Quebec' },
    { value: 'SK', label: 'Saskatchewan' },
    { value: 'YT', label: 'Yukon' },
  ],
};

const Address = ({ countries: options, country }, { _reduxForm: { sectionPrefix, change, dispatch } }) => {
  const state_options = country && STATE_OR_PROVINCE[country.code];
  return (
    <Fragment>
      <Select
        name="country"
        label="Country"
        options={options}
        validate={[required]}
        getOptionLabel={countryLabel}
        getOptionValue={countryValue}
        onChange={() => dispatch(change(`${sectionPrefix}.state_or_province`, null))}
        required
      />
      {state_options && (
        <SimpleSelect
          name="state_or_province"
          label="State or province"
          options={state_options}
          validate={[required]}
          required
        />
      )}
      <Columns colClassName="col-sm-6">
        <Input name="city" label="City" type="text" autoComplete="address-level2" validate={[required]} required />
        <Input
          name="zip_code"
          label="Zip code"
          type="text"
          col="col-sm-4"
          autoComplete="postal-code"
          validate={[required]}
          required
        />
      </Columns>
      <Input
        name="address"
        label="Street"
        type="text"
        col="col-sm-7"
        autoComplete="street-address"
        validate={[required]}
        required
      />
    </Fragment>
  );
};

// https://github.com/erikras/redux-form/commit/eb7c6fbfff545c994ef7425407698ac66d83a3c3#diff-d6364b6fea26c8847182793242eb4274
Address.contextTypes = {
  _reduxForm: PropTypes.shape({
    // form: PropTypes.string.isRequired,
    sectionPrefix: PropTypes.string,
    change: PropTypes.func,
    dispatch: PropTypes.func,
  }).isRequired,
};

export default formValues('country')(Address);
