import React, { Fragment } from 'react';
import { FormSection as Section, formValues } from 'redux-form';
import Address from './Address';
import { Input } from '../Form';

const Delivery = ({ shipping_same_as_billing, countries, className }) => (
  <div className={className}>
    <Section name="delivery_details">
      {!shipping_same_as_billing && (
        <Fragment>
          <h4>Delivery address</h4>
          <Address countries={countries} />
          <Input label="Company name" name="title" type="text" autoComplete="organization" />
        </Fragment>
      )}
    </Section>
  </div>
);

export default formValues('shipping_same_as_billing')(Delivery);
