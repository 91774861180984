import React from 'react';
import { connect } from 'react-redux';
import { submit, reduxForm, Field, isValid, isSubmitting } from 'redux-form';
import { required } from '../../Components/validators';
import Overview from './Overview';
import StepContainer from './StepContainer';

const Choice = ({ input, options }) => (
  <div className="form-group row">
    <legend className="col-form-label col-sm-3 pt-0">Choose shipping method:</legend>
    <div className="col-sm-7 bordered-options">
      {options.map(({ provider: value, title, price, is_free, transit_time }, idx) => (
        <div className="custom-control custom-radio" key={idx}>
          <input
            className="custom-control-input"
            type="radio"
            {...input}
            id={`${input.name}-${value}`}
            value={value || '-1'}
            checked={String(value) === input.value}
          />
          <label className="custom-control-label row" htmlFor={`${input.name}-${value}`}>
            <div className="col">
              <div>{title}</div>
              {transit_time && transit_time.min ? (
                <div className="text-muted">
                  {transit_time.min}-{transit_time.max} days
                </div>
              ) : null}
            </div>

            {is_free ? <div className="price">Free shipping</div> : <div className="price">&euro; {price}</div>}
          </label>
        </div>
      ))}
    </div>
  </div>
);

// We render empty form if there are no shipping options since our proceed condition is predicated by isFormValid
const Form = reduxForm({
  form: 'checkout_shipping',
})(({ options }) => (
  <form>
    <div className="alert alert-danger">
      <small>
        Delivery times for National Post Service and EMS Standard may be extended indefinitely due to Covid-19.
      </small>
    </div>
    {options.length > 0 ? (
      <Field
        name="shipping"
        component={Choice}
        options={options}
        validate={[required]}
        format={(v) => (v ? String(v.provider) : v)}
        normalize={(v) => options.find((o) => o.provider === v)}
      />
    ) : (
      <div className="alert alert-primary mt-5">
        <h4 className="alert-heading">Shipping quote for your address is unavailable</h4>
        <p>Your order will be processed manually and we will contact you with available options.</p>
        <hr />
        <p className="mb-0 small">Please proceed to the next step</p>
      </div>
    )}
  </form>
));

function Shipping({ canProceed, submit, ...props }) {
  return (
    <StepContainer title="Shipping" side={<Overview proceed={canProceed ? submit : undefined} />}>
      <Form {...props} />
    </StepContainer>
  );
}
const mapStateToProps = (state) => ({
  options: state.checkout.shipping_options,
  canProceed: isValid('checkout_shipping')(state) && !isSubmitting('checkout_shipping')(state),
});

const mergeProps = ({ options, canProceed }, { setShippingAndProceed, submit }) => ({
  options,
  canProceed,
  onSubmit: ({ shipping }) => setShippingAndProceed(shipping),
  submit,
});

export default connect(
  mapStateToProps,
  {
    setShippingAndProceed: (shipping) => ({
      type: 'checkout/submit_shipping',
      shipping,
    }),
    submit: () => submit('checkout_shipping'),
  },
  mergeProps,
)(Shipping);
