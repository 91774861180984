import React, { Fragment } from 'react';
import { Field } from 'redux-form';

import FormGroup from './FormGroup';
import * as Selects from './Select';
export * from './VATField';

export const bindField = (Component, processProps) => (props) => (
  <Field component={Component} {...(processProps ? processProps(props) : props)} />
);

// @TODO DRY
export const SimpleSelect = bindField(({ input, meta, ...props }) => {
  const error = meta.touched && meta.error && meta.error;
  const field = <Selects.SimpleSelect {...input} {...props} isInvalid={!!error} />;

  return props.label ? (
    <FormGroup {...props} error={error} htmlFor={input.name}>
      {field}
    </FormGroup>
  ) : (
    field
  );
});

export const Select = bindField(({ input, meta, ...props }) => {
  const error = meta.touched && meta.error && meta.error;
  const Select = props.loadOptions ? Selects.Async : Selects.Select;

  const field = <Select {...input} {...props} isInvalid={!!error} />;

  return props.label ? (
    <FormGroup {...props} error={error} htmlFor={input.name}>
      {field}
    </FormGroup>
  ) : (
    field
  );
});

export const Input = bindField(({ input, meta, ...props }) => {
  const error = meta.touched && meta.error && meta.error;
  const field = <input {...input} className={`form-control${error ? ' is-invalid' : ''}`} id={input.name} {...props} />;

  return props.label ? (
    <FormGroup {...props} error={error} htmlFor={input.name}>
      {field}
    </FormGroup>
  ) : (
    field
  );
});

export const TextArea = bindField(({ input, meta, ...props }) => {
  const error = meta.touched && meta.error && meta.error;

  const field = (
    <textarea {...input} className={`form-control${error ? ' is-invalid' : ''}`} id={input.name} {...props} />
  );

  return props.label ? (
    <FormGroup {...props} error={error} htmlFor={input.name}>
      {field}
    </FormGroup>
  ) : (
    field
  );
});

export const Checkbox = bindField(({ input: { value, ...input }, meta, label, help, required, ...props }) => {
  const error = meta.touched && meta.error && meta.error;
  return (
    <div className="custom-control custom-checkbox">
      <input
        type="checkbox"
        className={`custom-control-input${error ? ' is-invalid' : ''}`}
        {...input}
        id={input.name}
        checked={value}
        {...props}
      />
      <label className="custom-control-label" htmlFor={input.name}>
        {label} {required && <span className="asterisk">*</span>}
      </label>
      {error && <div className="invalid-feedback">{error}</div>}
      {help && <small className="form-text text-muted">{help}</small>}
    </div>
  );
});

export const Radio = bindField(({ input, meta, options, ...props }) => {
  const error = meta.touched && meta.error && meta.error;
  const field = options.map(({ label, value }, idx) => (
    <div className="custom-control custom-radio custom-control-inline" key={idx}>
      <input
        className="custom-control-input"
        type="radio"
        {...input}
        id={`${input.name}-${value}`}
        value={value}
        checked={value === input.value}
      />
      <label className="custom-control-label" htmlFor={`${input.name}-${value}`}>
        {label}
      </label>
    </div>
  ));

  return props.label ? (
    <FormGroup {...props} error={error} htmlFor={input.name}>
      <div className="d-flex">{field}</div>
    </FormGroup>
  ) : (
    field
  );
});

export const Meta = ({ warning, error }) =>
  warning || error ? (
    <Fragment>
      {warning && (
        <div className="alert alert-warning" role="alert">
          {warning}
        </div>
      )}
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
    </Fragment>
  ) : null;
