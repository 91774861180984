import { CheckoutOrderState, CheckoutState, steps } from './types';
import { Action, InitAction, SubmitOrderAction } from './actions';
import invariant from 'tiny-invariant';

function init({ order, countries, messages }: InitAction): CheckoutState {
  // checkout
  if (order.id == null) {
    return {
      step: 'CART',
      steps,
      order,
      restored: false,
      countries,
      messages,
      vat: undefined,
      shipping: undefined,
      shipping_options: undefined,
    };
    // restored
  } else {
    return {
      step: 'PAYMENT',
      steps,
      order,
      restored: true,
      countries,
      messages,
    };
  }
}

function submit_order(
  state: CheckoutOrderState,
  { orderFields, delivery: { vat, shipping_options } }: SubmitOrderAction,
): CheckoutState {
  return {
    ...state,
    // skip shipping?
    step: shipping_options === null ? 'PAYMENT' : 'SHIPPING',
    vat,
    // no shipping or keep old value
    shipping: shipping_options === null ? null : state.shipping,
    shipping_options,
    order: {
      ...state.order,
      ...orderFields,
    } as any,
  };
}

export function reducer(state: CheckoutState = {} as any, action: Action): CheckoutState {
  switch (action.type) {
    case 'checkout/init':
      return init(action);

    case 'checkout/set_messages':
      return {
        ...state,
        messages: action.messages,
      };

    case 'checkout/navigate':
      invariant(!state.restored);
      return {
        ...state,
        step: action.step,
      };

    case 'checkout/submit_cart':
      invariant(!state.restored);
      return {
        ...state,
        step: 'ORDER',
      };

    case 'checkout/submit_order':
      invariant(!state.restored);
      return submit_order(state, action);

    case 'checkout/submit_shipping':
      invariant(!state.restored);
      return {
        ...state,
        step: 'PAYMENT',
        shipping: action.shipping,
      };

    default:
      return state;
  }
}
