import memoize from 'lodash-es/memoize';

export const isEmpty = (value) => value === undefined || value === null || value === '';

export const email = (value) => {
  // Very naive regexp
  if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'Invalid email';
  }
  return null;
};

export const minInt = memoize((n) => (value) => {
  if (typeof n === 'undefined') {
    return null;
  }

  if (isEmpty(value)) {
    return null;
  }
  return parseInt(value, 10) < n ? `This field has to be at least ${n}` : null;
});

export const maxInt = memoize((n) => (value) => {
  if (typeof n === 'undefined') {
    return null;
  }

  if (isEmpty(value)) {
    return null;
  }
  return parseInt(value, 10) > n ? `This field has to be less than ${n}` : null;
});

export const required = (value) => (isEmpty(value) ? 'This field is required' : null);
export const isTrue = (value) => (!value ? 'This field has to be true' : null);
export const lineRequired = (value) => {
  if (!value || value.length === 0) {
    return 'At least one line required';
  }
  return null;
};

// export type Validator = (value: any) => string | undefined;
export function composeValidators(...validators) {
  return (value) => validators.reduce((error, validator) => error || validator(value), undefined);
}

export const requiredEmail = composeValidators(required, email);
