import React, { useState } from 'react';
import { Variation, AddToCartAction, QuantityState } from './types';
import { connect } from 'react-redux';
// @ts-ignore
import { actions } from 'state/cart';
import { ToCheckoutPrompt } from './ToCheckoutPrompt';

interface AddActionProps extends QuantityState {
  variation: Variation;
  available_after: string | null;
  add: AddToCartAction;
  checkoutUrl: string;
  sub_total: string;
  item_count: number;
  reset: () => void;
}

function AddActionContainer({
  available_after,
  variation,
  quantity,
  setQuantity,
  add,
  checkoutUrl,
  sub_total,
  item_count,
  reset,
}: AddActionProps): React.ReactElement {
  const [prompt, setPrompt] = useState<boolean>(false);

  const { id, in_stock, backorder } = variation;
  const out_of_stock = !in_stock && !backorder;

  function addClickHandler(): void {
    add(id, quantity).then((resp) => {
      if (resp.error) {
        throw resp.error;
      } else {
        setPrompt(true);
      }
    });
  }

  return (
    <>
      {prompt && (
        <ToCheckoutPrompt
          title={variation.title}
          sub_total={sub_total}
          item_count={item_count}
          checkoutUrl={checkoutUrl}
          close={() => {
            reset();
            setPrompt(false);
          }}
        />
      )}
      <div data-test-id="add-to-cart-quantity" className="quantity-control input-group mt-4 mb-2">
        <div className="input-group-prepend">
          <button
            className="btn btn-primary"
            type="button"
            disabled={quantity <= 1}
            onClick={() => setQuantity(quantity - 1)}
          >
            <i className="material-icons">remove</i>
          </button>
        </div>
        <input
          type="number"
          min="1"
          step="1"
          className="form-control"
          value={quantity}
          onChange={(e) => {
            const quantity = parseInt(e.target.value, 10) || 1;
            setQuantity(quantity);
          }}
        />
        <div className="input-group-append">
          <button className="btn btn-primary" type="button" onClick={() => setQuantity(quantity + 1)}>
            <i className="material-icons">add</i>
          </button>
        </div>
      </div>
      <button
        data-test-id="add-to-cart"
        className={`btn ${available_after ? 'btn-outline-primary' : 'btn-primary'} btn-lg btn-block`}
        onClick={addClickHandler}
        disabled={out_of_stock}
      >
        {out_of_stock ? 'Out of stock' : available_after ? 'Pre-order: Add to cart' : 'Add to cart'}
      </button>
    </>
  );
}

export const AddAction = connect(
  (state: any) => ({
    checkoutUrl: state.urls.checkout,
    item_count: state.cart.estimate.item_count,
    sub_total: state.cart.estimate.sub_total,
  }),
  {
    add: actions.addProduct,
  },
)(AddActionContainer);
