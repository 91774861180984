import React from 'react';
// @ts-expect-error
import { Field } from 'redux-form';
import { VATWidget } from './VATWidget';
import { validateVATNumber } from './util';

interface VATFieldProps {
  name: string;
  label: React.ReactNode;
}
export function VATField(props: VATFieldProps): React.ReactElement {
  return <Field component={VATWidget} {...props} validate={[validateVATNumber]} />;
}
