import React, {Component} from 'react';
import PropTypes from 'prop-types';


class Voucher extends Component {
  static propTypes = {
    voucher: PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired
    }),
    addVoucher: PropTypes.func.isRequired,
    removeVoucher: PropTypes.func.isRequired
  };

  state = {
    open: false,
    error: undefined
  }

  open = () => this.setState({open: true});
  close = () => this.setState({open: false});

  onBlur = (e) => {
    // min length
    if (e.target.value.length < 4){
      return
    }
    this.props.addVoucher(e.target.value)
      .then(res => {
        if (res.error) {

          // Bad code or expire voucher
          if (res.payload.status === 400) {
            const { payload: { response: {error}} } = res;
            this.setState({ error });
          } else {
            alert('ERROR')
          }
        } else {
          // At this point we have voucher in props
          this.setState({
            open: false
          });
        }
      })
  }

  onRemoveVoucher = () => {
    this.props.removeVoucher()
      .then(res => {
        if (res.error) {
          alert("ERROR")
        } else {
          this.setState({
            open: false
          });
        }
      })
  }

  render() {
    const {voucher} = this.props;
    const {open, error} = this.state;

    if (open) {
      return (
        <div className="row voucher">
          <div className="form-group col-sm-4">
            <label htmlFor="voucher" className="col-form-label">
              Enter a coupon code
            </label>

            <div className="input-group mb-3">
              <input
                className={`form-control ${error && 'is-invalid'}`}
                id="voucher"
                type="text"
                onBlur={this.onBlur}
              />
              <div className="input-group-append">
                <button className="btn" type="button" onClick={this.close}>
                  <i className="material-icons text-danger">close</i>
                </button>
              </div>
              {error && (
                <div className="invalid-feedback">
                  {error}
                </div>)}
            </div>
          </div>
        </div>
      )
    } else {
      if (voucher) {
        const {code} = voucher;
        return (
          <div className="input-group voucher">
            <div className="input-group-prepend">
              <div className="input-group-text">
                {code}
              </div>
            </div>
            <div className="input-group-append">
              <button className="btn" type="button" onClick={this.onRemoveVoucher}>
                <i className="material-icons text-danger">close</i>
              </button>
            </div>
          </div>
        )
      } else {
        return (
          <button className="btn btn-link voucher" onClick={this.open}>
            Have coupon code?
          </button>
        )
      }
    }
  }
}

export default Voucher;