import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import Billing from '../../Components/Details/Billing';
import Delivery from '../../Components/Details/Delivery';
import Contact from '../../Components/Details/Contact';

import { Checkbox, Input } from '../../Components/Form';
import { required, isTrue } from '../../Components/validators';
import StepContainer from './StepContainer';
import Overview from './Overview';
import AuthNotice from './AuthNotice';
import { submit } from 'redux-form';
import { submitOrder } from '../actions';

const Form = reduxForm({
  form: 'checkout_delivery',
})(({ countries, show_po_number, tosUrl }) => (
  <form>
    <Billing countries={countries} />
    <Delivery countries={countries} className="mt-4" />
    <Contact className="mt-4">{show_po_number && <Input label="PO number" name="po_number" type="text" />}</Contact>
    <Checkbox
      name="terms"
      label="I agree with site’s Terms & Conditions."
      help={
        <a href={tosUrl} target="_blank" rel="noreferrer">
          Read here.
        </a>
      }
      validate={[required, isTrue]}
      required
    />
  </form>
));

class Step extends Component {
  render() {
    const { countries, show_po_number, tosUrl, order, canProceed, submit } = this.props;
    return (
      <StepContainer title="Order" side={<Overview proceed={canProceed ? submit : undefined} />}>
        <AuthNotice />
        <Form
          countries={countries}
          show_po_number={show_po_number}
          initialValues={order}
          tosUrl={tosUrl}
          onSubmit={this.props.setOrderAndProceed}
        />
      </StepContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  tosUrl: state.urls.tos,
  order: state.checkout.order,
  countries: state.checkout.countries,
  show_po_number: state.cart.show_po_number,
  canProceed: (state) => isValid('checkout_delivery')(state) && !isSubmitting('checkout_delivery')(state),
});

export default connect(mapStateToProps, {
  setOrderAndProceed: submitOrder,
  submit: () => submit('checkout_delivery'),
})(Step);
