import Big from 'big.js';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../state/cart';
import AuthNotice from '../AuthNotice';
import Overview from '../Overview';
import StepContainer from '../StepContainer';
import FreeShippingAlert from './FreeShippingAlert';
import Item from './Item';
import Voucher from './Voucher';

class Cart extends Component {
  render() {
    const {
      outOfStock,
      backorder,
      minimumReached,
      cart: {
        voucher,
        discount_minimum,
        estimate: { rows, coupon, ...rest },
      },
      setProduct,
      removeProduct,
      addVoucher,
      removeVoucher,
      canProceed,
      submit,
    } = this.props;

    const voucherProps = {
      voucher,
      addVoucher,
      removeVoucher,
    };

    return (
      <StepContainer title="Shopping cart" side={<Overview proceed={canProceed ? submit : undefined} />}>
        <AuthNotice />
        {rows.length !== 0 && <FreeShippingAlert {...rest} />}
        {!minimumReached && <div className="alert alert-danger">Minimal item sum is {discount_minimum}</div>}
        {outOfStock && (
          <div className="alert alert-danger mt-3" data-test-id="out-of-stock-message">
            <i className="material-icons text-danger mr-2">error</i> Cart contains products that are out of stock and no
            longer available.
          </div>
        )}
        {backorder && (
          <div className="alert alert-warning mt-3" data-test-id="backorder-message">
            <i className="material-icons text-warning mr-2">error</i> Products in the cart may take{' '}
            <strong>1 week</strong> to dispatch.
          </div>
        )}
        {rows.length === 0 ? (
          <div className="alert alert-info mt-3">Your cart is empty</div>
        ) : (
          <Fragment>
            <table className="table table-striped checkout-cart">
              <tbody>
                {rows.map((item, idx) => (
                  <Item {...item} key={idx} setProduct={setProduct} removeProduct={removeProduct} />
                ))}
                {coupon !== '0.00' && (
                  <tr>
                    <td className="title">
                      <div className="voucher-discount">Cupon discount</div>
                    </td>
                    <td className="quantity d-none d-sm-table-cell" />
                    <td className="text-right sum">
                      <div className="voucher-discount">&euro; -{coupon}</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="mb-3 mb-md-0">
              <Voucher {...voucherProps} />
            </div>
          </Fragment>
        )}
      </StepContainer>
    );
  }
}

const selectMinimumReached = (state) => {
  const {
    estimate: { sub_total },
    discount_minimum,
  } = state.cart;
  return discount_minimum ? Big(discount_minimum).lt(Big(sub_total)) : true;
};

const selectOutOfStock = (state) => state.cart.estimate.rows.some((r) => !r.in_stock && !r.backorder);
const selectBackorder = (state) => state.cart.estimate.rows.some((r) => !r.in_stock && r.backorder);

const mapStateToProps = (state) => {
  return {
    outOfStock: selectOutOfStock(state),
    backorder: selectBackorder(state),
    cart: state.cart,
    minimumReached: selectMinimumReached(state),
    canProceed:
      selectMinimumReached(state) &&
      state.cart.estimate.rows.length > 0 &&
      !state.cart.estimate.rows.some((r) => !r.in_stock && !r.backorder),
  };
};

export default connect(mapStateToProps, {
  ...actions,
  submit: () => ({
    type: 'checkout/submit_cart',
  }),
})(Cart);
