import { ProductType } from 'Modules/Catalog';

export const steps = ['CART', 'ORDER', 'SHIPPING', 'PAYMENT', 'DONE'] as const;

export type Step = typeof steps[number];

export interface Country {
  code: string;
  name: string;
}

export interface Message {
  tags: string;
  content: string;
}

export interface ShippingOption {
  extra: any;
  is_free: boolean;
  method: string;
  price: string;
  provider: string;
  signature: string;
  title: string;
  transit_time: null | {
    min: number;
    max: number;
  };
}

export type OrderFields = {
  shipping_same_as_billing: boolean;
  billing_details: object;
  delivery_details: object;
  is_post_pay: boolean;
  delivery_notes: string;
  order_notes: string;
  po_number: string;
  terms: string;
};

type CheckoutOrder = OrderFields & {
  id: null;
  cart: number;
};

export type OrderLine = {
  // @TODO
  type: ProductType;
  total: string;
};

type RestoredOrder = OrderFields & {
  id: number;
  cart: undefined;
  lines: OrderLine[];
  sub_total: string;
  vat_rate: number;
  vat_amount: string;
  total: string;
  deliverable: boolean;
};

export type Order = RestoredOrder | CheckoutOrder;

export function isRestored(order: RestoredOrder | CheckoutOrder): order is RestoredOrder {
  return (order as RestoredOrder).id !== null;
}

type BaseState = {
  steps: readonly Step[];
  countries: Country[];
  messages: Message[];
};

export type CheckoutOrderState = {
  step: Step;
  restored: false;
  order: CheckoutOrder;
  vat: undefined | string;
  shipping_options: undefined | ShippingOption[] | null;
  shipping: undefined | ShippingOption | null;
} & BaseState;

export type RestoredOrderState = {
  step: 'PAYMENT';
  restored: true;
  order: RestoredOrder;
} & BaseState;

export type CheckoutState = CheckoutOrderState | RestoredOrderState;

export type EstimateRow = {
  product_id: null;
  title: string;
  price: string;
  quantity: number;
  // # Invoice view
  unit_discount_sum: string;
  is_preorder: boolean;
  unit_discounted_value: string;
  unit_discounted_sum: string;
  uinit_discount: string;
  // Cart fields
  backorder: boolean;
  in_stock: boolean;
};

export type Cart = {
  voucher: {
    id: number;
    code: string;
  } | null;
  available_after: string | null;
  discount_minimum: string | null;
  enable_post_pay: boolean;
  show_po_number: boolean;
  is_authenticated: boolean;
  estimate: {
    item_count: number;
    rows: EstimateRow[];
    sub_total: string;
    coupon: string;
    free_shipping: boolean;
    free_shipping_threshold: string | null;
    deliverable: boolean;
  };
};

export type State = {
  checkout: CheckoutState;
  urls: Record<string, string>;
  cart: Cart;
};

export type DeliveryResponse = {
  vat: number;
  shipping_options: ShippingOption[] | null;
};
