import React from 'react';
// @ts-expect-error
import FormGroup from '../FormGroup';
// @ts-expect-error
import { Select, selectStyle } from '../Select';
import { Prefix } from './types';
import { prefixOptions } from './prefixOptions';
import { parseValue } from './util';

interface VATWidgetProps {
  label: React.ReactNode;
  input: {
    // null|undefined for legacy or new?
    value: string;
    onChange: (v: string) => void;
    onBlur: (v: any) => void;
  };
  meta: any;
}

const prefixSelectProps = {
  options: prefixOptions,
  getOptionLabel: ({ code, name }: Prefix): string => `${code} - ${name}`,
  getOptionValue: ({ code }: Prefix): string => code,
  formatOptionLabel: ({ code, name }: Prefix, { context }: { context: 'menu' | 'value' }): React.ReactNode =>
    context == 'value' ? (
      code
    ) : (
      <>
        {code} - <small>{name}</small>
      </>
    ),
  isClearable: true,
  placeholder: 'Prefix',
  components: {
    DropdownIndicator: null,
  },
  styles: {
    ...selectStyle,
    menu: (base: any) => ({
      ...base,
      minWidth: '200px',
    }),
  },
};

export function VATWidget({ label, input: { value, onChange, onBlur }, meta }: VATWidgetProps): React.ReactElement {
  const error = meta.touched && meta.error && meta.error;

  const [prefix, number] = parseValue(value);

  function onPrefixChange(prefix: Prefix | null): void {
    onChange(prefix ? `${prefix.code}${number}` : '');
  }

  function onNumberChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const number = e.target.value;
    onChange(prefix ? `${prefix.code}${number}` : number);
  }

  function onFieldBlur(): void {
    onBlur(undefined);
  }

  return (
    <FormGroup label={label} error={error} help="Valid VAT number registered in European Union">
      <div className="row">
        <div className="col-12 col-md-5">
          <Select {...prefixSelectProps} value={prefix} onChange={onPrefixChange} onBlur={onFieldBlur} />
        </div>
        <div className="col-12 col-md-7 pl-md-0">
          <input
            className={`form-control${error ? ' is-invalid' : ''}`}
            type="text"
            placeholder="Number"
            disabled={!value}
            value={number}
            onChange={onNumberChange}
            onBlur={onFieldBlur}
          />
        </div>
      </div>
    </FormGroup>
  );
}
