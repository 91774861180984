import React from 'react';
import Media from 'react-media';

import Desktop from  './Desktop';
import Mobile from './Mobile';


export default (props) => (
  <Media query={{maxWidth: '767.98px'}}>
    {matches => matches? (<Mobile {...props} />) : (<Desktop {...props} />)}
  </Media>
)