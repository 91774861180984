// @TODO remove OrderLineType
export const LineType = Object.freeze({
  PRODUCT: 'PRODUCT',
  SERVICE: 'SERVICE',
  DISCOUNT: 'DISCOUNT',
  SHIPPING: 'SHIPPING',
});

export const ShippingMethod = Object.freeze({
  NONE: 'NONE',
  SENDO: 'SENDO',
  SUTI: 'SUTI',
  MANUAL: 'MANUAL',
});

// @TODO remove
export const OrderStatus = Object.freeze({
  PENDING: 'PENDING',
  DRAFT: 'DRAFT',
  CONFIRMED: 'CONFIRMED',
  // WAITING: 'WAITING'  # runtime only (CONFIRMED + waiting for preorder)
  DONE: 'DONE',
  CANCELED: 'CANCELED',
});

export const InvoiceStatus = Object.freeze({
  DRAFT: 'DRAFT',
  CONFIRMED: 'CONFIRMED',
  CANCELED: 'CANCELED',
  DONE: 'DONE',
});

export const InvoicePaymentStatus = Object.freeze({
  PENDING: 'PENDING', //Yellow
  LATE: 'LATE', // RED
  PAID: 'PAID', // GREEN
  // @TODO partial?
});

export const DeliveryStatus = Object.freeze({
  DRAFT: 'DRAFT',
  CONFIRMED: 'CONFIRMED',
  INITIATED: 'INITIATED',
  ASSIGNED: 'ASSIGNED',
  SHIPPED: 'SHIPPED',
  BLOCKED: 'BLOCKED',
  // # Legacy
  // CREATED = 'CREATED'
  CANCELED: 'CANCELED',
});

export const IssueStatus = Object.freeze({
  DRAFT: 'DRAFT',
  CONFIRMED: 'CONFIRMED',
  CANCELED: 'CANCELED',
  DONE: 'DONE',
});

export const IssueMethod = Object.freeze({
  SHIPMENT_TO_CUSTOMER: 'SHIPMENT_TO_CUSTOMER',
  SCRAP: 'SCRAP',
  INVENTORY: 'INVENTORY',
  OUT_OF_BAND: 'OUT_OF_BAND',
});

export const ReceiptMethod = Object.freeze({
  PURCHASE: 'PURCHASE',
  INVENTORY: 'INVENTORY',
  INITIAL_STOCK: 'INITIAL_STOCK',
  PRODUCTION: 'PRODUCTION',
});

export const RELATED_DOC_STATES = {
  PENDING: 'PENDING',
  PARTIAL: 'PARTIAL',
  CONSUMED: 'CONSUMED',
};

export const OrderInvoiceState = Object.freeze(RELATED_DOC_STATES);
export const OrderGoodsState = Object.freeze(RELATED_DOC_STATES);

// @TODO remove in favor of  'Modules/Warehouse';
export const MoveState = Object.freeze({
  CONFIRMED: 'CONFIRMED',
  PARTIALLY_ASSIGNED: 'PARTIALLY_ASSIGNED',
  ASSIGNED: 'ASSIGNED',
  DONE: 'DONE',
});

export const ExpenseStatus = Object.freeze({
  DRAFT: 'DRAFT',
  CONFIRMED: 'CONFIRMED',
  CANCELED: 'CANCELED',
  DONE: 'DONE',
});

export const LocationType = Object.freeze({
  SUPPLIER: 'SUPPLIER',
  INTERNAL: 'INTERNAL',
  OUTGOING: 'OUTGOING',
});

export const BomType = Object.freeze({
  PRODUCT: 'PRODUCT',
  KIT: 'KIT',
});

export const ProductionAction = Object.freeze({
  ASSEMBLY: 'ASSEMBLY',
  DISASSEMBLY: 'DISASSEMBLY',
});

export const ProductionRoute = Object.freeze({
  PRODUCTION: 'PRODUCTION',
  WORKORDER: 'WORKORDER',
});

export const ProductionState = Object.freeze({
  DRAFT: 'DRAFT',
  CONFIRMED: 'CONFIRMED',
  IN_PRODUCTION: 'IN_PRODUCTION',
  DONE: 'DONE',
});
