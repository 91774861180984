import React, { Component, Fragment } from 'react';
import Big from 'big.js';
import { connect } from 'react-redux';
// @ts-ignore
import { formValueSelector } from 'redux-form';

const shippingFormSelector = formValueSelector('checkout_shipping');

const selectShippingPrice = (state) => {
  const shipping = shippingFormSelector(state, 'shipping');
  if (shipping) {
    return shipping.is_free ? '0.00' : shipping.price;
  }
  return undefined;
};

const selectOverview = (state) => {
  const {
    estimate: { sub_total, coupon },
    available_after,
  } = state.cart;

  const shipping = selectShippingPrice(state);
  const vat_rate = state.checkout.vat;

  let vat_amount = undefined;
  let total = undefined;

  if (typeof shipping !== 'undefined' && typeof vat_rate !== 'undefined') {
    const totalApplicable = Big(sub_total)
      .minus(coupon)
      .add(shipping);

    vat_amount = totalApplicable.times(Big(vat_rate).div(100));
    total = vat_amount.add(totalApplicable).toFixed(2);
    vat_amount = vat_amount.toFixed(2);
  }

  return {
    available_after,

    sub_total,
    coupon: Big(coupon).eq(0) ? undefined : coupon,
    discounted: Big(sub_total)
      .minus(coupon)
      .toFixed(2),

    shipping,

    vat_rate,
    vat_amount,

    total,
  };
};

class Overview extends Component {
  render() {
    const {
      proceed,
      overview: {
        available_after,

        sub_total,
        coupon,
        discounted,

        shipping,

        vat_rate,
        vat_amount,

        total,
      },
    } = this.props;
    return (
      <div className="cart-overview">
        <h4>Order overview</h4>

        <div className="row">
          <div className="col text-left">Subtotal</div>
          <div className="col text-right">&euro; {sub_total}</div>
        </div>

        {typeof coupon !== 'undefined' && (
          <Fragment>
            <div className="row coupon">
              <div className="col text-left">Cupon discount</div>
              <div className="col text-right">&euro; -{coupon}</div>
            </div>
            <div className="row">
              <div className="col text-left">Price after discount</div>
              <div className="col text-right">&euro; {discounted}</div>
            </div>
          </Fragment>
        )}
        <div className="row">
          <div className="col text-left">Shipping</div>
          <div className="col text-right">{shipping ? `€ ${shipping}` : `—`}</div>
        </div>

        <div className="row">
          <div className="col text-left">VAT {vat_rate ? `${vat_rate}%` : null}</div>
          <div className="col text-right">{vat_amount ? `€ ${vat_amount}` : `—`}</div>
        </div>

        {vat_rate === "0.00" && (
          <p className="small text-muted">Not paid by users and companies in European Union with valid VAT number</p>
        )}

        <hr />

        <div className="row total">
          <div className="col text-left">Total</div>
          <div className="col text-right">{total ? `€ ${total}` : `—`}</div>
        </div>
        {available_after && (
          <div className="pre-order-alert">
            Please note! Your cart has pre-order item (-s).
            <br />
            Estimated shipping due {available_after}
          </div>
        )}

        <button
          className="btn btn-lg btn-primary btn-block mt-3"
          onClick={proceed}
          disabled={!proceed}
          data-test-id="proceed-to-checkout"
        >
          Proceed to checkout
        </button>
        <div id="payment-methods" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  overview: selectOverview(state),
  vat: state.checkout.vat,
});

export default connect(mapStateToProps)(Overview);
