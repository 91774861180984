import React from 'react';
import { FormSection as Section } from 'redux-form';
import { Input, TextArea } from '../Form';
import { required, email } from '../validators';
import { Columns } from '../Form/Layout';

const Contact = ({ className, instructionField, children }) => (
  <div className={className}>
    <Section name="delivery_details">
      <h4>Recipient information</h4>
      <Columns colClassName="col-sm-6">
        <Input
          label="First name"
          name="first_name"
          type="text"
          autoComplete="given-name"
          validate={[required]}
          required
        />
        <Input
          label="Last name"
          name="last_name"
          type="text"
          autoComplete="family-name"
          validate={[required]}
          required
        />
      </Columns>

      <Input label="Phone number" name="phone" type="text" autoComplete="tel" validate={[required]} required />
      <Input label="E-mail" name="email" type="email" autoComplete="email" validate={[required, email]} required />
    </Section>
    <TextArea
      label="Delivery instructions"
      name={instructionField || 'delivery_notes'}
      col="col-sm-7"
      help="Instructions for courier: delivery time, door code, etc. These notes will be printed on the shipping label."
    />
    {!instructionField && (
      <TextArea
        label="Order notes"
        name="order_notes"
        col="col-sm-7"
        help="Anything else you want to tell us about this order."
      />
    )}
    {children}
  </div>
);

export default Contact;
