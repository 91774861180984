import { FetchClient } from '@ff-it/api';
import { RSAA } from 'redux-api-middleware';
import stringify from 'qs/lib/stringify';
import { getCookie } from './utils';

const csrftoken = getCookie('csrftoken');
export const apiClient = new FetchClient({
  baseURL: '/api',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRFToken': csrftoken,
  },
  credentials: 'include',
});

export const rejectOnError = (resp) => (resp && resp.error ? Promise.reject(resp) : resp);

export const createRequestTypes = (type) => [`${type}_REQUEST`, `${type}_SUCCESS`, `${type}_FAILURE`];

const META = {
  headers: {
    'Content-Type': 'application/json',
    'X-CSRFToken': csrftoken,
  },
  credentials: 'include', // Move up
};

export const createRequest = (requestFactory) => (...args) => ({
  [RSAA]: {
    ...META,
    ...requestFactory(...args),
  },
});

async function getJSON(res) {
  const contentType = res.headers.get('Content-Type');
  const emptyCodes = [204, 205];

  if (!~emptyCodes.indexOf(res.status) && contentType && ~contentType.indexOf('json')) {
    return await res.json();
  } else {
    return await Promise.resolve();
  }
}

const doFetch = ({ endpoint, ...options }) => {
  if (options.headers['Content-Type'] === 'multipart/form-data') {
    delete options.headers['Content-Type'];
  }
  return fetch(endpoint, options).then(
    (response) =>
      response.ok
        ? getJSON(response).then((payload) => ({ payload, response }))
        : getJSON(response).then((payload) =>
            Promise.reject({
              error: true,
              payload: {
                status: response.status,
                response: payload,
              },
            }),
          ),
    (err) => {
      console.log('err', err);
    },
  );
};

export const fetchRequest = (requestFactory) => (...args) => {
  const { headers, ...request } = requestFactory(...args);
  return doFetch({
    headers: {
      ...META.headers,
      ...headers,
    },
    credentials: 'include',
    ...request,
  });
};

export const createActions = ({ endpoint }) => ({
  fetch: fetchRequest((id) => ({
    endpoint: `${endpoint}${id}/`,
    method: 'GET',
  })),
  remove: fetchRequest((id) => ({
    endpoint: `${endpoint}${id}/`,
    method: 'DELETE',
  })),
  list: fetchRequest((queryParams) => ({
    endpoint: `${endpoint}?${stringify(queryParams)}`,
    method: 'GET',
  })),
  create: fetchRequest((body) => ({
    endpoint,
    method: 'POST',
    body: JSON.stringify(body),
  })),
  update: fetchRequest((id, payload, partial = false) => ({
    endpoint: `${endpoint}${id}/`,
    method: partial ? 'PATCH' : 'PUT',
    body: JSON.stringify(payload),
  })),
});
