import { Prefix } from './types';
import { prefixOptions } from './prefixOptions';

export function parseValue(value: string): [Prefix | null, string] {
  let prefix: Prefix | null = null;
  let number = '';
  if (value) {
    const prefixCode = value.substr(0, 2);
    prefix = prefixOptions.find((o) => o.code === prefixCode) || null;
    number = prefix ? value.substr(2) : value;
  }

  return [prefix, number];
}

export function validateVATNumber(value: string): string | null {
  if (value) {
    const [prefix, tail] = parseValue(value);

    if (prefix) {
      if (!tail) {
        return 'Number required';
      }
      if (!value.match(prefix.pattern)) {
        return 'Invalid number';
      }
    }
  }
  return null;
}
