import React from 'react';
import { History } from 'history';

import { Product } from './types';
import { useControllerState } from './useControllerState';
import { SingleVariation } from './SingleVariation';
import { VariationChooser } from './VariationChooser';
import { AddAction } from './AddAction';

interface CartControlsProps {
  product: Product;
  history: History;
}

export function CartControls({
  product: { title, available_after, variations },
  history,
}: CartControlsProps): React.ReactElement {
  const state = useControllerState(variations, history);

  // @TODO confimration modal
  return (
    <div className="cart-controls">
      {state.single ? <h4>{title}</h4> : <h4>Select {title} version</h4>}
      {state.single ? (
        <SingleVariation {...state.variation} />
      ) : (
        <VariationChooser {...state} variations={variations} />
      )}
      {available_after && (
        <div className="text-danger mt-4">
          Estimated shipping due <strong>{available_after}</strong>
        </div>
      )}
      {state.variation === null ? (
        <button className="btn btn-primary btn-lg btn-block mt-4" disabled>
          Select a version first
        </button>
      ) : (
        <AddAction
          available_after={available_after}
          variation={state.variation}
          quantity={state.quantity}
          setQuantity={state.setQuantity}
          reset={state.reset}
        />
      )}
    </div>
  );
}
