import { createRequestTypes } from '../../api';

const NS = 'cart';

const ADD_PRODUCT = createRequestTypes(`${NS}/ADD_PRODUCT`);
const SET_PRODUCT = createRequestTypes(`${NS}/SET_PRODUCT`);
const ADD_VOUCHER = createRequestTypes(`${NS}/ADD_VOUCHER`);
const REMOVE_VOUCHER = createRequestTypes(`${NS}/REMOVE_VOUCHER`);

export {
  ADD_PRODUCT,
  SET_PRODUCT,

  ADD_VOUCHER,
  REMOVE_VOUCHER
};