import { useEffect, useState, useCallback } from 'react';
import { Variation, ControllerState } from './types';
import { History } from 'history';
import { parse } from 'qs';

export function useControllerState(variations: Variation[], history: History): ControllerState {
  const [quantity, setQuantity] = useState<number>(1);

  // @TODO load choice from querystring
  const [id, setId] = useState<number | null>(null);

  useEffect(() => {
    if (variations.length !== 1) {
      const query: any = parse(history.location.search, { ignoreQueryPrefix: true });
      const choice = parseInt(query.v, 10);
      if (variations.find((v) => v.id === choice)) {
        setId(choice);
      }
    }
  }, []);

  const setVariation = useCallback((id: number | null) => {
    history.replace({ ...history.location, search: id ? `?v=${id}` : undefined });
    setId(id);
  }, []);

  const reset = useCallback(() => {
    if (variations.length !== 1) {
      if (history.location.search) {
        history.replace({ ...history.location, search: undefined });
      }
      setId(null);
    }
    setQuantity(1);
  }, [id]);

  if (variations.length === 1) {
    return {
      single: true,
      variation: variations[0],
      quantity,
      setQuantity,
      reset,
    };
  }

  return {
    single: false,
    variation: variations.find((v) => v.id === id) || null,
    setVariation,
    quantity,
    setQuantity,
    reset,
  };
}
