import React from 'react';
import { connect } from 'react-redux';
import { CheckoutState, Message } from './types';

interface MessagesProps {
  messages: Message[];
}

function Messages({ messages }: MessagesProps): React.ReactElement | null {
  return messages.length > 0 ? (
    <div className="container">
      {messages.map(({ tags, content }, idx) => (
        <div
          className={`alert alert-${tags} mt-5`}
          role="alert"
          key={idx}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      ))}
    </div>
  ) : null;
}

function mapStateToProps(state: { checkout: CheckoutState }): MessagesProps {
  return { messages: state.checkout.messages };
}

export default connect(mapStateToProps)(Messages);
