import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import configureStore from './state/configureStore';

import { CartControls } from './Scenes/Product';
import Badge from './Cart/Badge';
import Checkout from './Checkout';

const history = createBrowserHistory();

let store = undefined;

const cart = (preloadedState) => {
  store = configureStore(preloadedState);

  const itemCount = document.getElementById('cart-icon');
  itemCount &&
    ReactDOM.render(
      <Provider store={store}>
        <Badge />
      </Provider>,
      itemCount,
    );

  const addToCart = document.getElementById('add-to-cart');
  if (addToCart) {
    const product = JSON.parse(addToCart.dataset.product);
    ReactDOM.render(
      <Provider store={store}>
        <CartControls product={product} history={history} />
      </Provider>,
      addToCart,
    );
  }
};

const checkout = ({ countries, order, messages }) => {
  store.dispatch({
    type: 'checkout/init',
    countries,
    order,
    messages,
  });

  ReactDOM.render(
    <Provider store={store}>
      <Checkout />
    </Provider>,
    document.getElementById('checkout'),
  );
};

export default {
  cart,
  checkout,
};
