import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import cart from './cart';
import { reducer as checkout } from '../Checkout/reducer';

const urls = (state = null) => state;

export default combineReducers({
  cart,
  checkout,
  urls,
  form,
});
