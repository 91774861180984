import React from 'react';
import { default as ReactSelect } from 'react-select';
import { default as ReactSelectAsync } from 'react-select/async';
import SimpleValue from './SimpleValue';

export const selectStyle = {
  container: (base) => ({
    ...base,
    fontSize: '14px',
  }),
  control: (base, { isFocused, isDisabled, selectProps: { isInvalid } }) => ({
    ...base,
    borderColor: isInvalid ? '#e3241a' : 'rgba(0, 0, 0, 0.1)',
    backgroundColor: isDisabled ? '#e7e7e7' : '#f7f7f7',
    ...(isFocused
      ? {
          borderColor: 'rgba(0, 0, 0, 0.3)',
          boxShadow: '0 0 0 0.2rem rgba(8, 0, 0, 0.25)',
        }
      : undefined),
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '6px 8px',
  }),
  singleValue: (base) => ({
    ...base,
    color: '#495057',
  }),
};

const makeSimple = (Component) => ({ defaultValue, getOptionValue, isMulti, options, value, onChange, ...props }) => (
  <SimpleValue
    options={options}
    value={value}
    defaultValue={defaultValue}
    getOptionValue={defaultValue}
    isMulti={isMulti}
    onChange={onChange}
  >
    {(simpleProps) => <Component {...props} {...simpleProps} />}
  </SimpleValue>
);

const createSelect = (Component) => {
  // @TODO invlaid

  return ({ onBlur, selectRef, ...props }) => (
    <Component
      classNamePrefix="form-select"
      styles={selectStyle}
      {...props}
      ref={selectRef}
      // Don't update the value but blur the field
      onBlur={() => onBlur && onBlur(undefined)}
    />
  );
};

export const Select = createSelect(ReactSelect);
export const SimpleSelect = makeSimple(Select);
export const Async = createSelect(ReactSelectAsync);
