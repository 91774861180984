import React from 'react';
import cx from 'clsx';
import { Variation } from './types';

interface VariationChooserProps {
  variation: Variation | null;
  variations: Variation[];
  setVariation: (id: number) => void;
}

export function VariationChooser({ variations, variation, setVariation }: VariationChooserProps): React.ReactElement {
  return (
    <div className="variation-chooser">
      {variations.map(({ id, title, base_price, sale_price, discounted_price }) => (
        <div className="custom-control custom-radio" key={id}>
          <input
            type="radio"
            id={`variation-${id}`}
            name="varation"
            className="custom-control-input"
            checked={id === variation?.id}
            onChange={() => setVariation(id)}
          />
          <label className="custom-control-label row" htmlFor={`variation-${id}`}>
            <span className="col">{title}</span>
            <span
              className={cx('col p', {
                'has-discount': discounted_price,
                'has-sale': sale_price,
              })}
            >
              {discounted_price && <div className="discounted-price">&euro; {discounted_price}</div>}
              {sale_price && <div className="sale-price">&euro; {sale_price}</div>}
              <div className="base-price">&euro; {base_price}</div>
            </span>
          </label>
        </div>
      ))}
    </div>
  );
}
