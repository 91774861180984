// @ts-ignore
import { SubmissionError } from 'redux-form';
import { ThunkAction } from 'redux-thunk';
import { Order, Message, Country, Step, State, OrderFields, DeliveryResponse, ShippingOption } from './types';
// @ts-ignore
import { createRequest, createRequestTypes, rejectOnError } from '../api';
import { AnyAction } from 'redux';

export type InitAction = {
  type: 'checkout/init';
  order: Order;
  countries: Country[];
  messages: Message[];
};

export type SetMessagesAction = {
  type: 'checkout/set_messages';
  messages: Message[];
};

export type NavigateAction = {
  type: 'checkout/navigate';
  step: Exclude<Step, 'DONE'>;
};

export type SubmitCartAction = {
  type: 'checkout/submit_cart';
};

export type SubmitOrderAction = {
  type: 'checkout/submit_order';
  orderFields: OrderFields;
  delivery: DeliveryResponse;
};

const _postDelivery = createRequest((body: any) => ({
  endpoint: (state: State) => state.urls.delivery,
  method: 'POST',
  types: createRequestTypes(`checkout/DELIVERY`),
  body: JSON.stringify(body),
}));

export const submitOrder = (orderFields: OrderFields): ThunkAction<void, State, unknown, AnyAction> => async (
  dispatch,
) => {
  const resp = await dispatch(_postDelivery(orderFields));
  if (resp && resp.error) {
    throw new SubmissionError(resp.payload.response);
  }
  dispatch({
    type: 'checkout/submit_order',
    orderFields: orderFields,
    delivery: resp.payload,
  });
};

export type SubmitShippingAction = {
  type: 'checkout/submit_shipping';
  shipping: ShippingOption;
};

const _postOrder = createRequest((body: any) => ({
  endpoint: (state: State) => state.urls.checkout,
  method: 'POST',
  types: createRequestTypes(`checkout/SUBMIT`),
  body: JSON.stringify(body),
}));

const _postRestoredOrder = createRequest((body: any) => ({
  endpoint: (state: State) => `${state.urls.checkout}restore/`,
  method: 'POST',
  types: createRequestTypes(`checkout/SUBMIT`),
  body: JSON.stringify(body),
}));

export const submitPayment = (payment_provider = null): ThunkAction<void, State, unknown, AnyAction> => async (
  dispatch,
  getState,
) => {
  const { checkout }: State = getState();

  if (checkout.restored) {
    return await dispatch(
      _postRestoredOrder({
        order: checkout.order.id,
        payment_provider,
      }),
    ).then(rejectOnError);
  } else {
    return await dispatch(
      _postOrder({
        ...checkout.order,
        shipping: checkout.shipping,
        payment_provider,
      }),
    ).then(rejectOnError);
  }
};

export type Action =
  | InitAction
  | SetMessagesAction
  | NavigateAction
  | SubmitCartAction
  | SubmitOrderAction
  | SubmitShippingAction;
