import React from "react";

const HORIZONTAL = 'horizontal';
const VERTICAL = 'vertical';

export const LAYOUT = {
  HORIZONTAL: {
    name: HORIZONTAL,
    formGroupClassName: 'row',
    labelClassName: 'col-sm-3 col-form-label',
    colClassName: 'col-sm-5'
  },
  WIDE: {
    name: HORIZONTAL,
    formGroupClassName: 'row',
    labelClassName: 'col-sm-3 col-form-label',
    colClassName: 'col-sm-9'
  },
  VERTICAL: {
    name: VERTICAL
  },
}

const Layout =  React.createContext(LAYOUT.HORIZONTAL);

export const Columns = ({children, colClassName}) => (
  <Layout.Consumer>
    {({ name }) => name === VERTICAL
      ? (
        <div className="form-row">
          <Layout.Provider value={{...LAYOUT.VERTICAL, formGroupClassName: colClassName}}>
            {children}
          </Layout.Provider>
        </div>
    ) : children}
  </Layout.Consumer>
)

export default Layout;

