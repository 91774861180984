import { createRequest } from '../../api';
import * as types from './types';

const addProduct = createRequest((product, quantity = 1) => ({
  endpoint: (state) => state.urls.cart,
  method: 'POST',
  body: JSON.stringify({ product, quantity }),
  types: types.ADD_PRODUCT,
}));

const setProduct = createRequest((product, quantity) => ({
  endpoint: (state) => state.urls.cart,
  method: 'PUT',
  body: JSON.stringify({ product, quantity }),
  types: types.SET_PRODUCT,
}));

const removeProduct = (product) => setProduct(product, 0);

const addVoucher = createRequest((code) => ({
  endpoint: (state) => state.urls.cartVoucher,
  method: 'POST',
  body: JSON.stringify({ code }),
  types: types.ADD_VOUCHER,
}));

const removeVoucher = createRequest(() => ({
  endpoint: (state) => state.urls.cartVoucher,
  method: 'DELETE',
  types: types.REMOVE_VOUCHER,
}));

export { addProduct, setProduct, removeProduct, addVoucher, removeVoucher };
