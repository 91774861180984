import Big from 'big.js';

// @ts-ignore
import { LineType } from 'const';
import invariant from 'tiny-invariant';
import { State, RestoredOrderState, CheckoutOrderState, Cart } from './types';

type Summary = {
  finalPrice: boolean;
  showCatDiscount: boolean;
  isPostPay: boolean;
  rows: any[];
  totals: {
    sub_total: string;
    coupon: string | undefined;
    vat_rate: number;
    vat_amount: string | undefined;
    total: string | undefined;
    shipping_price: string | undefined;
    shipping_title: string | undefined;
  };
};

const selectRestoredSummary = (checkout: RestoredOrderState): Summary => {
  const {
    order: { lines, sub_total, vat_rate, vat_amount, total, deliverable },
  } = checkout;
  const rows: any = [];
  let shipping: any;
  let coupon = undefined;

  lines.forEach(({ type, total, ...line }) => {
    // Should assert just one?
    if (type === LineType.SHIPPING) {
      shipping = line;
    } else if (type === LineType.DISCOUNT) {
      coupon = total;
    } else {
      rows.push({ unit_discounted_sum: total, ...line });
    }
  });

  return {
    // not deliverable or shipping presnet
    finalPrice: !deliverable || shipping,
    showCatDiscount: rows.some(({ unit_discount_sum }: any) => Big(unit_discount_sum).gt(0)),
    isPostPay: false, // Can't be post pay
    rows,
    totals: {
      // :<
      sub_total:
        typeof coupon === 'undefined'
          ? sub_total
          : Big(sub_total)
              .minus(coupon)
              .toFixed(2),
      coupon,
      vat_rate,
      vat_amount,
      total,

      // If shipping as free, price is 0.00 here
      shipping_price: shipping?.price,
      shipping_title: shipping?.title,
    },
  };
};

const selectEstimatedSummary = (checkout: CheckoutOrderState, cart: Cart): Summary => {
  const {
    estimate: { rows, sub_total, coupon },
    enable_post_pay,
  } = cart;

  const { vat: vat_rate, shipping: shippingOption } = checkout;
  invariant(typeof vat_rate !== 'undefined');

  let shipping_price, shipping_title, vat_amount, total;

  // This is null if we failed to estimate delivery price and will be filled out after
  // User can create an order and pay after we've set shipping price
  if (shippingOption) {
    shipping_title = shippingOption.title;
    shipping_price = shippingOption.is_free ? '0.00' : shippingOption.price;

    const totalApplicable = Big(sub_total)
      .minus(coupon)
      .add(shipping_price);

    vat_amount = totalApplicable.times(Big(vat_rate).div(100));
    total = vat_amount.add(totalApplicable).toFixed(2);
    vat_amount = vat_amount.toFixed(2);
  } else if (shippingOption === null) {
    const totalApplicable = Big(sub_total).minus(coupon);
    vat_amount = totalApplicable.times(Big(vat_rate).div(100));
    total = vat_amount.add(totalApplicable).toFixed(2);
    vat_amount = vat_amount.toFixed(2);
  }

  return {
    finalPrice: typeof shippingOption !== 'undefined',
    showCatDiscount: rows.some(({ unit_discount_sum }) => Big(unit_discount_sum).gt(0)),
    isPostPay: enable_post_pay,
    rows,
    totals: {
      sub_total,
      coupon: Big(coupon).eq(0) ? undefined : coupon,

      shipping_title,
      shipping_price,

      vat_rate,
      vat_amount,

      total,
    },
  };
};

export const selectSummary = (state: State): Summary =>
  state.checkout.restored ? selectRestoredSummary(state.checkout) : selectEstimatedSummary(state.checkout, state.cart);
