import React from 'react';
import Modal from 'react-modal';

interface ToCheckoutPromptPops {
  title: string;
  item_count: number;
  sub_total: string;
  close: () => void;
  checkoutUrl: string;
}

export function ToCheckoutPrompt({
  title,
  item_count,
  sub_total,
  checkoutUrl,
  close,
}: ToCheckoutPromptPops): React.ReactElement {
  return (
    <Modal
      isOpen={true}
      contentLabel="Checkout prompt"
      style={{
        overlay: {
          zIndex: 100,
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
      }}
      className={{
        base: 'modal fade d-block',
        afterOpen: 'show',
        beforeClose: 'modal fade d-block',
      }}
      ariaHideApp={false}
      onRequestClose={close}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
    >
      <div className="modal-dialog modal-dialog-centered" role="document" id="cart-confirmation">
        <div className="modal-content">
          <div className="modal-body">
            <div className="row information">
              <div className="col-6">
                <div className="item-name strong mb-1">{title}</div>
                <div>Was added to cart!</div>
              </div>
              <div className="col-6 text-right">
                <div className="mb-1">
                  Subtotal (<span className="num-items">{item_count}</span> items )
                </div>
                <div className="strong">
                  &euro;<span className="subtotal">{sub_total}</span>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-md-6">
                <button type="button" className="btn btn-lg btn-outline-secondary btn-block" onClick={close}>
                  <i className="material-icons">arrow_back</i> Continue shopping
                </button>
              </div>
              <div className="col-12 col-md-6 mt-3 mt-md-0">
                <a href={checkoutUrl} className="btn btn-lg btn-primary btn-block" data-test-id="to-checkout">
                  To checkout
                </a>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            + Shipping <br />+ 21% VAT for individual customers in EU without EU VAT registration number
          </div>
        </div>
      </div>
    </Modal>
  );
}
