import React, { Fragment } from 'react';

export const Card = ({ title, children }) => (
  <div className="card">
    <h4>{title}</h4>
    <div className="card-body">{children}</div>
  </div>
);

const Header = ({
  shipping_same_as_billing: same,
  billing_details: { type, ...billing },
  delivery_details,
  delivery_notes,
  order_notes,
  po_number,
}) => {
  const isCompany = type === 'company';

  const title = isCompany ? billing.title : `${billing.first_name} ${billing.last_name}`;

  const numbers = isCompany
    ? [billing.registration_number, billing.vat_number ? billing.vat_number : undefined]
    : [billing.vat_number ? billing.vat_number : undefined];

  const payee = {
    title,
    children: [
      ...numbers,
      billing.country.name,
      billing.state_or_province ? `${billing.city}, ${billing.state_or_province}` : billing.city,
      billing.zip_code,
      billing.address,
    ],
  };

  // @FIXME this should no longer be necessary since we copy details over even if they are same
  let delivery = null;
  if (delivery_details || same) {
    delivery = same
      ? payee
      : {
          title: delivery_details.title,
          children: [
            ...numbers,
            delivery_details.country.name,
            delivery_details.state_or_province
              ? `${delivery_details.city}, ${delivery_details.state_or_province}`
              : delivery_details.city,
            delivery_details.zip_code,
            delivery_details.address,
          ],
        };
  }

  return (
    <div className="payment-cards card-deck">
      <Card title="Payee">
        <h5 className="card-title">{payee.title}</h5>
        {payee.children.map((c, i) => (
          <div className="card-text" key={i}>
            {c}
          </div>
        ))}
      </Card>
      {delivery && (
        <Fragment>
          <Card title="Delivery">
            <h5 className="card-title">{delivery.title}</h5>
            {delivery.children.map((c, i) => (
              <div className="card-text" key={i}>
                {c}
              </div>
            ))}
          </Card>
          <Card title="Contact">
            <h5 className="card-title">{`${delivery_details.first_name} ${delivery_details.last_name}`}</h5>
            <div className="card-text">{delivery_details.phone}</div>
            <div className="card-text">{delivery_details.email}</div>
            {delivery_notes && <div className="card-text mt-3">{delivery_notes}</div>}
            {order_notes && <div className="card-text mt-3">{order_notes}</div>}
            {po_number && <div className="card-text  mt-3">PO: {po_number}</div>}
          </Card>
        </Fragment>
      )}
    </div>
  );
};

export default Header;
