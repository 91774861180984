import React, { Component } from 'react';
import classNames from 'classnames';
import debounce from 'lodash-es/debounce';

class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: props.quantity,
    };

    this.ensureProductDebounced = debounce(this.ensureProduct, 300);
  }

  onQuantityChange = (e) => {
    const quantity = parseInt(e.target.value, 10) || 1;
    this.setState({ quantity }, this.ensureProductDebounced);
  };

  ensureProduct = () => {
    if (this.props.quantity !== this.state.quantity) {
      this.props.setProduct(this.props.product_id, this.state.quantity);
    }
  };

  componentWillReceiveProps({ quantity }) {
    if (quantity !== this.state.quantity) {
      this.setState({
        quantity,
      });
    }
  }

  removeProduct = () => {
    this.props.removeProduct(this.props.product_id);
  };

  render() {
    const {
      out_of_stock,
      hard_stock,
      title,
      is_preorder,
      price,
      unit_discount_sum,
      unit_discounted_value,
      unit_discounted_sum, // total
    } = this.props;
    const { quantity } = this.state;
    return (
      <tr>
        <td className="title">
          <div>
            {title}
            {is_preorder && ' (pre-order)'}
          </div>
          <button onClick={this.removeProduct} className="btn btn-link text-danger">
            remove <i className="material-icons">close</i>
          </button>
        </td>
        <td className="price">
          {unit_discount_sum === '0.00' ? (
            <div>{price}</div>
          ) : (
            <>
              <del className="small">{price}</del>
              <div className="text-danger">{unit_discounted_value}</div>
            </>
          )}
        </td>
        <td className="quantity">
          {out_of_stock && (
            <i
              className={classNames('material-icons mr-2', hard_stock ? 'text-danger' : 'text-warning')}
              title="Out of stock"
            >
              error
            </i>
          )}
          <input
            value={quantity}
            onChange={this.onQuantityChange}
            type="number"
            min="1"
            step="1"
            className={classNames({ out_of_stock, hard_stock })}
          />
        </td>
        <td className="text-right sum d-none d-sm-table-cell">
          <div className="value">{unit_discounted_sum}</div>
        </td>
      </tr>
    );
  }
}

export default Item;
