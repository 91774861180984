// https://github.com/jossmac/react-select-simple-value
// https://github.com/JedWatson/react-select/issues/2679
// + onChange

function flatten(arr) {
  return arr.reduce((acc, val) => (Array.isArray(val.options)
    ? acc.concat(flatten(val.options))
    : acc.concat(val)
  ), []);
}
const clean = x => x.trim();
const toArray = str => str.split(',').map(clean);
const toString = arr => arr.join(',');

function getValue(opts, val, getOptVal, isMulti) {
  if (val === undefined) return undefined;

  const options = flatten(opts);
  return isMulti
    ? options.filter(o => toArray(val).includes(getOptVal(o)))
    : options.find(o => getOptVal(o) === val);
}

// Component

const defaultGetOptionValue = opt => opt? opt.value : opt;

function ReactSelectSimpleValue({
  children,
  defaultValue: simpleDefault,
  getOptionValue = defaultGetOptionValue,
  isMulti = false,
  options,
  value: simpleValue,
  onChange: onChangeHandler
}) {
  const value = getValue(options, simpleValue, getOptionValue, isMulti) || null;
  const defaultValue = getValue(options, simpleDefault, getOptionValue, isMulti);
  // @TODO not sure if this works on null/undefined
  const onChange = (newValue, action) => onChangeHandler(getOptionValue(newValue), action);

  return children({
    defaultValue,
    getOptionValue,
    isMulti,
    options,
    value,
    onChange
  });
}

export default ReactSelectSimpleValue;