import React, { Component, Fragment } from 'react';
import { LoadingContainer } from '@ff-it/ui';
import { connect } from 'react-redux';
import Header from '../../../Components/PaymentHeader';
import Table from './Table';
import StepContainer from '../StepContainer';
import { selectSummary } from '../../selectors';
import { submitPayment } from '../../actions';

class Payment extends Component {
  state = {
    loading: false,
  };

  submit = (method) => {
    this.setState({ loading: true });
    return this.props
      .submit(method)
      .then(({ payload }) => {
        document.location.replace(payload);
      })
      .catch(
        ({
          payload: {
            response: { _error },
          },
        }) => {
          this.setState({ loading: false });
          this.props.setMessages([
            {
              tags: 'danger',
              content: _error,
            },
          ]);
        },
      );
  };

  render() {
    const {
      order,
      summary: { finalPrice, isPostPay, rows, totals: summary, showCatDiscount },
    } = this.props;

    return (
      <StepContainer
        title="Payment"
        side={
          <>
            <h3>Proceed</h3>
            <LoadingContainer loading={this.state.loading}>
              {finalPrice && !isPostPay ? (
                <Fragment>
                  <button
                    className="btn btn-lg btn-primary btn-block mt-3"
                    onClick={() => this.submit('EVERYPAY')}
                    disabled={this.state.loading}
                  >
                    <i className="material-icons">credit_card</i> Creditcard
                  </button>
                  <button
                    className="btn btn-lg btn-primary btn-block mt-3"
                    onClick={() => this.submit('PAYPAL_V2')}
                    disabled={this.state.loading}
                  >
                    <i className="pp-icon" /> PayPal
                  </button>
                </Fragment>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block mt-3"
                  onClick={() => this.submit()}
                  disabled={this.state.loading}
                >
                  Complete order
                </button>
              )}
            </LoadingContainer>
          </>
        }
      >
        <div>
          <h4>Seller</h4>
          <p>
            <strong>Erica Synths, SIA</strong>
            <br />
            Tīklu iela 3 Riga Latvia LV-1048
          </p>

          <Header {...order} />
          <h4 className="mt-3 mt-md-5">Your order</h4>
          <Table items={rows} summary={summary} finalPrice={finalPrice} showCatDiscount={showCatDiscount} />
        </div>
      </StepContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  order: state.checkout.order,
  summary: selectSummary(state),
});

export default connect(mapStateToProps, {
  submit: submitPayment,
  setMessages: (messages) => ({
    type: 'checkout/set_messages',
    messages,
  }),
})(Payment);
