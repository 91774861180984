import React, { Fragment } from 'react';
import classNames from 'classnames';
import Layout from './Layout';

const FormGroup = ({ children, htmlFor, error, required, label, help }) => (
  <Layout.Consumer>
    {({ formGroupClassName, labelClassName, colClassName }) => {
      const content = (
        <Fragment>
          {children}
          {error && <div className="invalid-feedback d-block">{error}</div>}
          {help && <small className="form-text text-muted">{help}</small>}
        </Fragment>
      );

      const finalContent = colClassName ? <div className={colClassName}>{content}</div> : content;

      return (
        <div className={classNames('form-group', formGroupClassName)}>
          <label htmlFor={htmlFor} className={labelClassName}>
            {label} {required && <span className="asterisk">*</span>}
          </label>
          {finalContent}
        </div>
      );
    }}
  </Layout.Consumer>
);

export default FormGroup;
