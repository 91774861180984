import React from 'react';
import cx from 'clsx';
import { Variation } from './types';

export function SingleVariation({ discounted_price, sale_price, base_price }: Variation): React.ReactElement {
  return (
    <div
      className={cx('d-flex flex-row flex-wrap justify-content-center prices', {
        'has-discount': discounted_price,
        'has-sale': sale_price,
      })}
    >
      {discounted_price && <span className="discounted-price">{`€ ${discounted_price}`}</span>}
      {sale_price && <span className="sale-price">{`€ ${sale_price}`}</span>}
      {base_price && <span className="base-price">{`€ ${base_price}`}</span>}
    </div>
  );
}
