import React from 'react';
import { useSelector } from 'react-redux';
import { Nav } from './Nav';
import Messages from './Messages';
import steps from './steps';
import { State, Step } from './types';
import invariant from 'tiny-invariant';

function selectStep(state: State): Step {
  return state.checkout.step;
}

function Checkout(): React.ReactElement {
  const step = useSelector(selectStep);
  const Step = steps[step];
  invariant(Step);

  return (
    <div className="pb-0 pb-md-5">
      <div className="steps d-flex align-items-center">
        <div className="container">
          <Nav />
        </div>
      </div>
      <Messages />
      <Step />
    </div>
  );
}

export default Checkout;
