import { Step } from '../types';

// @ts-ignore
import Cart from './Cart';
// @ts-ignore
import Order from './Order';
// @ts-ignore
import Shipping from './Shipping';
// @ts-ignore
import Payment from './Payment';

const steps: Partial<Record<Step, React.ComponentType>> = {
  CART: Cart,
  ORDER: Order,
  SHIPPING: Shipping,
  PAYMENT: Payment,
};

export default steps;
