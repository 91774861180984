import React from 'react';
import Summary from './Summary';


const Row = ({ title, price, quantity, unit_discount, unit_discount_sum, unit_discounted_sum }) => (
  <tr>
    <td>{title}</td>
    <td className="text-right">{quantity}</td>
    <td className="text-right">{unit_discounted_sum}</td>
  </tr>
);


const Table = ({items, summary, finalPrice}) => (
  <table className="table summary-table mt-1">
    <thead>
      <tr>
        <th>Item</th>
        <th className="text-right">Qt.</th>
        <th className="text-right">Sum, &euro;</th>
      </tr>
    </thead>
    <tbody>
      {items.map((item, idx) => (<Row {...item} key={idx} />))}
    </tbody>
    <Summary
        {...summary}
        finalPrice={finalPrice}
        colspan={2}
    />
  </table>
);

export default Table;