import React, { useEffect } from 'react';

interface StepContainerProps {
  // STEP key data attr
  title: React.ReactNode;
  children: React.ReactNode;
  side?: React.ReactNode;
}

function StepContainer({ title, children, side }: StepContainerProps): React.ReactElement {
  useEffect(() => {
    window.requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });
  }, []);

  const main = (
    <>
      <h3 className="mb-3">{title}</h3>
      {children}
    </>
  );

  const content = side ? (
    <div className="row">
      <div className="col-12 col-md-8">{main}</div>
      <div className="col-12 col-md-4 mobile-fullwidth">
        <div className="side-nav">{side}</div>
      </div>
    </div>
  ) : (
    main
  );

  return <div className="container mt-2 mt-md-5">{content}</div>;
}

export default StepContainer;
