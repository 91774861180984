import * as types from './types';


const cart = (state=null, action) => {
  switch (action.type) {
    case types.ADD_PRODUCT[1]:
    case types.SET_PRODUCT[1]:
    case types.ADD_VOUCHER[1]:
    case types.REMOVE_VOUCHER[1]:
      return action.payload

    default:
      return state;
  }
}

export default cart;

