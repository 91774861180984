import React from 'react';

const Summary = ({
  colspan,

  sub_total,
  coupon,

  shipping_price,
  shipping_title,

  vat_rate,
  vat_amount,
  total,

  finalPrice,
}) => {
  let rows = [['Subtotal', sub_total]];

  if (typeof coupon !== 'undefined') {
    rows.push(['Cupon code', coupon]);
  }

  if (typeof shipping_title !== 'undefined') {
    rows.push([`Shipping (${shipping_title})`, shipping_price]);
  }
  if (finalPrice) {
    rows = rows.concat([
      [`VAT ${vat_rate}%`, vat_amount],
      ['Total', total],
    ]);
  }

  return (
    <tfoot>
      {rows.map(([lable, value], idx) => (
        <tr key={idx}>
          <td colSpan={colspan} className="text-right">
            {lable}
          </td>
          <td className="text-right">{value}</td>
        </tr>
      ))}
    </tfoot>
  );
};

export default Summary;
