import React from 'react';
import { connect } from 'react-redux';

const AuthNotice = ({ isAuthenticated, loginUrl, signupUrl }) =>
  isAuthenticated ? null : (
    <div className="alert alert-info mb-4">
      Consider{' '}
      <a className="font-weight-bold" href={loginUrl}>
        logging in
      </a>{' '}
      or{' '}
      <a className="font-weight-bold" href={signupUrl}>
        signing up
      </a>{' '}
      to benefit from the loyalty program and get a permanent discount for purchases!
    </div>
  );

const mapStateToProps = (state, ownProps) => ({
  isAuthenticated: state.cart.is_authenticated,
  loginUrl: state.urls.login,
  signupUrl: state.urls.signup,
});

export default connect(mapStateToProps)(AuthNotice);
