import React, { Fragment } from 'react';
import { connect } from 'react-redux';

const Badge = ({ item_count }) => (
  <Fragment>
    {item_count ? <span className="badge badge-pill badge-danger">{item_count}</span> : null}
    <i className="material-icons">shopping_basket</i>
  </Fragment>
);

const mapStateToProps = (state, ownProps) => ({
  item_count: state.cart.estimate.item_count,
});

export default connect(mapStateToProps)(Badge);
